
'use strict';

// Load dependencies and cache frequently used elements
var $ = require('classie');
var root = document.documentElement;

var app = {

  /**
   * Application container.
   *
   * @type  {Element}
   */
  container: document.querySelector('.app'),

  /**
   * Element reprenting the application toolbar.
   *
   * @type  {Element}
   */
  toolbar: document.querySelector('.js-toolbar'),

  /**
   * Element that allows to toggle the toolbar visibility.
   *
   * @type  {Element}
   */
  toolbarToggle: document.querySelector('.js-toolbar-toggle'),

  /**
   * Initialize the application.
   */
  init: function() {

    // Initialize document root
    $.add(root, 'js');
    $.remove(root, 'no-js');

  },

  /**
   * Register the application for DOM events.
   */
  bind: function() {

    var self = this;

    this.toolbarToggle.addEventListener('click', function(e) {
      self.toggleToolbar();
      e.preventDefault();
    });

  },

  /**
   * Show the application toolbar.
   */
  showToolbar: function() {
    $.add(this.container, 'with-toolbar');
  },

  /**
   * Hide the application toolbar.
   */
  hideToolbar: function() {
    $.remove(this.container, 'with-toolbar');
  },

  /**
   * Toggle the visibility of the toolbar.
   */
  toggleToolbar: function() {
    $.toggle(this.container, 'with-toolbar');
  },

};

app.init();
app.bind();
